<template>
  <div class="invite">
    <div class="head">
      <img @click="back" src="@/assets/images/back.png" class="arrow_img" />
      <span>{{ coinName }}</span>
    </div>
    <van-popover v-model="showPopover" :border="false" trigger="click" :actions="actions" @select="onSelect" id="popover">
      <template #reference>
        <div class="picker">
          <span class="text">{{ typeText || '全部订单' }}</span>
          <img class="down" referrerpolicy="no-referrer" src="@/assets/images/order/down.png" />
        </div>
      </template>
    </van-popover>
    <div class="table">
      <div class="th flex">
        <span class="text"> 类型 </span>
        <span class="text"> 变动金额 </span>
        <span class="text"> 余额 </span>
        <span class="text"> 时间 </span>
      </div>
      <div class="tbody">
        <template v-if="total > 0">
          <van-list v-model="loading" :finished="finished" :finished-text="finishText" @load="onLoad">
            <div class="flex item" v-for="(item, index) in list" :key="index">
              <span class="text"> {{ item.action_name }} </span>
              <span class="text" :class="item.change_amount > 0 ? 'green' : 'red'">
                {{ item.change_amount > 0 ? '+' + item.change_amount : item.change_amount }}
              </span>
              <span class="text"> {{ item.present_amount || 0 }} </span>
              <span class="text"> {{ dateFormatFn(item.create_time) }} </span>
            </div>
          </van-list>
        </template>
        <template v-else>
          <div class="nodata item">
            <span> 暂无数据 </span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { homeApi } from '@/api/index';
import { dateFormat } from '@/utils/formatTool.js';
export default {
  data() {
    return {
      actions: [],
      list: [],
      showPopover: false,
      typeText: '全部',
      coin_type: '',
      action: -1,
      coinName: 'USDT',
      page: 0,
      total: 1,
      loading: true,
      finished: false,
      typeList: [],
      finishText: '加载更多',
    };
  },
  mounted() {
    this.coin_type = this.$route.query.coin_type;
    this.coinName = this.$route.query.coin_name || 'USDT';
    this.coinAction();
    this.onLoad();
    window.addEventListener('scroll', this.scrollBottom);
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollBottom);
  },
  methods: {
    //返回上一页
    back() {
      this.$router.back();
    },
    scrollBottom() {
      //加载更多
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop; //变量windowHeight是可视区的高度
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight; //变量scrollHeight是滚动条的总高度
      var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight; //滚动条到底部的条件
      console.log(scrollTop, 'scrollTop');
      console.log(windowHeight, 'windowHeight');
      console.log(scrollHeight, 'scrollHeight');
      if (scrollTop + windowHeight >= scrollHeight && scrollTop > 0) {
        //写后台加载数据的函数
        console.log('滚动到底部啦');
        if (this.finished) {
          this.loading = true; // 关闭加载状态
          this.finished = false; // 加载开始
          this.finishText = '加载更多';
          this.onLoad();
        }
      } else {
        // this.loading = false; // 关闭加载状态
      }
    },
    dateFormatFn(date) {
      return dateFormat(new Date(date * 1000), 'yyyy-MM-dd hh:mm:ss');
    },
    onSelect(action) {
      this.action = action.id;
      this.typeText = action.text;
      this.page = 0;
      this.loading = true;
      this.finished = false;
      this.list = [];
      this.onLoad();
    },
    coinAction() {
      let params = {
        coin_type: this.coin_type,
      };
      homeApi.coinAction(params).then(res => {
        if (res.code == 200) {
          this.typeList = res.data;
          let actionList = res.data;
          let keys = Object.keys(actionList);
          let values = Object.values(actionList);
          this.actions.push({ text: '全部', id: -1 });
          for (let index = 0; index < keys.length; index++) {
            this.actions.push({ text: values[index], id: keys[index] });
          }
        }
      });
    },
    coinList(coin_type, action) {
      let params = {
        page: this.page,
        query: {
          coin_type: coin_type,
          action: action,
        },
      };
      homeApi.coinList(params).then(res => {
        if (res.code == 200) {
          let rows = res.data.rows;

          // 如果返回的数组是空或数组长度是0
          if (rows == null || rows.length === 0) {
            this.loading = false; // 关闭加载状态
            this.finished = true; // 加载结束
            this.finishText = '没有更多了';
            if (this.list.length <= 0) {
              this.total = 0;
            }
            return;
          }
          this.loading = false; // 关闭加载状态
          this.finishText = '上滑加载更多';
          this.finished = true;
          this.total = res.data.total; //数据总条数

          this.list = this.list.concat(rows); //合并数组

          // 如果合并之后的数组长度大于返回的数据总条数
          if (this.list.length >= this.total) {
            this.finished = true; // 加载结束
            this.finishText = '没有更多了';
          }
        } else {
          this.total = 0;
          this.loading = false; // 关闭加载状态
          this.finished = true; // 加载结束
          this.finishText = '没有更多了';
        }
      });
    },
    onLoad() {
      this.page += 1;
      this.coinList(this.coin_type, this.action);
    },
  },
};
</script>
  
<style lang="less" scoped>
.invite {
  min-height: calc(100vh + 50px);
  padding-top: 50px;
  .head {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(#000, 0.8);
    letter-spacing: 1.5px;
    font-weight: 550;
    background: #fff;
    padding: 14px 0;
    .arrow_img {
      position: absolute;
      left: 20px;
      width: 10px;
      height: 16px;
    }
  }
  .picker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    min-width: 115px;
    height: 40px;
    margin: 16px 16px 0;
    padding: 16px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 20px;
    box-shadow: 4px 4px 15px 0px rgba(180, 212, 212, 0.29);
    .text {
      color: rgba(51, 51, 51, 1);
      font-size: 13px;
      font-weight: 500;
      padding-right: 10px;
    }
    .down {
      width: 12px;
      height: 6px;
    }
  }
  .green {
    color: #10d1bc;
  }
  .red {
    color: #e55c29;
  }
  .table {
    box-shadow: 4px 4px 15px 0px rgba(180, 212, 212, 0.29);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 16px;
    margin: 16px;
    color: #000;
    font-size: 13px;
    padding: 16px;

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .text {
      display: block;
      width: 25%;
      text-align: center;
    }
    .th {
      font-weight: 550;
      color: rgba(#000, 0.86);
      padding-bottom: 16px;
    }
    .tbody {
      font-size: 12px;
      color: rgba(83, 89, 122, 1);

      .item {
        padding: 10px 0;
        border-top: 1px solid rgba(232, 240, 247, 1);
      }
      .nodata {
        text-align: center;
        line-height: 380%;
      }
    }
  }
}
</style>