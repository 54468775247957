<template>
  <div class="invite">
    <div class="head">
      <img @click="back" src="@/assets/images/back.png" class="arrow_img" />
      <span>邀请链接</span>
    </div>
    <div class="content_box">
      <div class="invite_box">
        <div class="code">
          <div class="qrcode" id="id" ref="id"></div>
        </div>
        <div class="invite_address">
          <span>{{ inviteLink }}</span>
        </div>
      </div>
    </div>
    <div class="hint">
      <div>
        <span>复制链接发给您的朋友，一块来体验吧！</span>
      </div>
    </div>
    <div class="replicate">
      <span class="copy copys" @click="copy" :data-clipboard-text="this.inviteLink">复制链接</span>
    </div>
  </div>
</template>

<script>
import Clipboard from 'clipboard';
import { homeApi } from '@/api/index';
import QRCode from 'qrcodejs2';
export default {
  data() {
    return {
      inviteLink: '',
      qrcode: '',
    };
  },
  watch: {
    inviteLink(newText) {
      this.createQrcode();
    },
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    //返回上一页
    back() {
      this.$router.back();
    },
    copy() {
      if (this.inviteLink) {
        var clipboard = new Clipboard('.copys');
        clipboard.on('success', e => {
          this.$toast('复制成功');
          clipboard.destroy(); // 释放内存
        });
        clipboard.on('error', e => {
          this.$toast('复制失败,浏览器不支持');
          clipboard.destroy(); // 释放内存
        });
      }
    },
    createQrcode() {
      if (this.qrcode) {
        this.$refs.id.innerHTML = '';
      }
      this.qrcode = new QRCode(this.$refs.id, {
        text: this.inviteLink,
        width: 170,
        height: 170,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    getUserInfo() {
      homeApi.getUserInfo().then(res => {
        if (res.code == 200) {
          this.inviteLink = res.data.invite_link;
          this.createQrcode();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.invite {
  min-height: 100vh;
  padding: 66px 16px 0;
  .head {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(#000, 0.8);
    letter-spacing: 1.5px;
    font-weight: 550;
    background: #fff;
    padding: 14px 0;
    .arrow_img {
      position: absolute;
      left: 20px;
      // top: 46%;
      width: 10px;
      height: 16px;
      // transform: translate(0, -50%);
    }
  }
  .content_box {
    height: 365px;
    width: 100%;
    background: url('~@/assets/images/invite/background.png') no-repeat;
    background-size: 100% 100%;
    .invite_box {
      // background: #fff;
      border-radius: 10px;
      padding: 40px 20px;
      .code {
        display: flex;
        justify-content: center;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 16px;
        height: 200px;
        width: 200px;
        margin: 14% auto;
        .qrcode {
          margin: auto;
        }
      }
      .invite_address {
        font-size: 12px;
        color: rgba(#fff, 0.9);
        text-align: center;
        // overflow: hidden; //超出的文本隐藏
        // text-overflow: ellipsis; //溢出用省略号显示
        // white-space: nowrap; //溢出不换行
        margin: -30px 0 0;
        span {
          word-wrap: break-word;
          white-space: pre-line;
          overflow-wrap: break-word;
        }
      }
    }
  }
  .hint {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    font-size: 12px;
    border-radius: 10px;
    padding: 20px;
    .describe {
      display: flex;
      align-items: center;
      // display: inline-block;
      padding: 10px 10px;
      margin-top: 20px;
      .describes_img {
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
    }
  }
  .replicate {
    display: flex;
    justify-content: center;
    font-size: 15px;
    .copy {
      width: 100%;
      color: rgba(#fff, 0.95);
      // line-height: 44px;
      background: #7938CF;
      border-radius: 12px;
      text-align: center;
      padding: 12px 40px;
      // margin: 0 16px;
    }
  }
}
</style>