<template>
  <div class="withdraw">
    <div class="head">
      <img @click="back" src="@/assets/image/arrow.png" class="arrow_img" />
      <span>{{ title }}</span>
    </div>
    <div class="total_box">
      <span>{{ coinText }}</span>
      <span class="total">{{ Math.floor(ammount * 100) / 100 || 0 }}</span>
    </div>

    <div class="caption">
      <span>{{ type == 1 ? 'ETC明细' : 'USDT明细' }}</span>
      <van-popover v-model="showPopover" placement="bottom-end" trigger="click" :actions="actions" @select="onSelect">
        <template #reference>
          <span class="right_arrow">{{ typeText || '全部' }}</span>
        </template>
      </van-popover>
    </div>

    <div class="list_title">
      <span>类型</span>
      <span style="text-align: center">数量</span>
      <span style="text-align: right">日期</span>
    </div>
    <div v-if="total < 1" class="bare">
      <span>暂无数据</span>
    </div>
    <div v-else>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" class="list_box">
        <!-- <van-cell v-for="(item,index) in list" :key="index" :title="item.title" /> -->
        <div v-for="(item, index) in list" :key="index" class="li">
          <!--   <span v-if="type == 1">{{ item.action == -1 ? '全部' : item.action == 20 ? '算力收益' : item.action == 21 ? '管道收益' : '未知' }}</span>
          <span v-if="type == 2">{{ item.action == -1 ? '全部' : item.action == 10 ? '复购算力' : item.action == 11 ? '代数收益' : item.action == 12 ? '加权分红' : '未知' }}</span> -->
          <span>{{ typeFormatFn(item.action) }}</span>
          <span style="text-align: center">{{ Math.floor(item.change_amount * 100) / 100 || 0 }}</span>
          <span style="text-align: right">{{ dateFormatFn(item.create_time) }}</span>
        </div>
      </van-list>
    </div>
  </div>
</template>
  
  <script>
import { homeApi } from '@/api/index';
import { dateFormat, typemat } from '@/utils/formatTool.js';
export default {
  data() {
    return {
      list: [],
      page: 0,
      loading: false,
      finished: false,
      total: 1,
      title: undefined,
      coinText: undefined,
      type: 0, // 1ETC  2USDT
      ammount: 0,
      actions: [],
      showPopover: false,
      typeText: '全部',
      actionType: -1, //-1:全部 0:未知 1:租赁 2:直推奖励 3:团队的算力奖励 4:团队的平级算力奖励,
    };
  },
  mounted() {
    this.type = this.$route.query.type;
    this.getAccountInfo();
    if (this.type == 1) {
      // ETC总额
      this.title = 'ETC资产明细';
      this.coinText = 'ETC总额';
      this.actionGet(3);
    } else if (this.type == 2) {
      // USDT余额
      this.title = 'USDT资产明细';
      this.coinText = 'USDT余额';
      this.actionGet(2);
    } else {
    }
    // this.onLoad();
  },
  methods: {
    //返回上一页
    back() {
      this.$router.back();
    },
    dateFormatFn(date) {
      return dateFormat(new Date(date *1000), 'yyyy-MM-dd hh:mm:ss');
    },
    typeFormatFn(type) {
      return typemat(type);
    },
    getAccountInfo() {
      homeApi.getUserInfo({}).then(res => {
        if (res.code == 200) {
          if (this.type == 1) {
            this.ammount = res.data.token;
          } else if (this.type == 2) {
            this.ammount = res.data.usdt;
          }
        }
      });
    },
    actionGet(type) {
      let params = {
        type,
      };
      homeApi.actionGet(params).then(res => {
        if (res.code == 200) {
          this.actions = res.data;
        }
      });
    },
    usdtList() {
      let params = {
        page: this.page,
        query: {
          action: this.actionType,
        },
      };
      this.loading = true;
      this.finished = false;
      homeApi.usdtList(params).then(res => {
        console.log(res);
        if (res.code == 200) {
          let rows = res.data.rows;
          // 如果返回的数组是空或数组长度是0
          if (rows == null || rows.length === 0) {
            this.loading = false; // 关闭加载状态
            this.finished = true; // 加载结束
            this.total = 0
            return;
          }
          this.loading = false; // 关闭加载状态
          this.total = res.data.total; //数据总条数
          this.list = this.list.concat(rows); //合并数组
          console.log(this.list);

          // 如果合并之后的数组长度大于返回的数据总条数
          if (this.list.length >= this.total) {
            this.finished = true; // 加载结束
          }
        } else {
          this.total = 0;
          this.loading = false; // 关闭加载状态
          this.finished = true; // 加载结束
        }
      });
    },
    tokenList() {
      let params = {
        page: this.page,
        query: {
          action: this.actionType,
        },
      };
      this.loading = true;
      this.finished = false;
      homeApi.tokenList(params).then(res => {
        console.log(res);
        if (res.code == 200) {
          let rows = res.data.rows;
          // 如果返回的数组是空或数组长度是0
          if (rows == null || rows.length === 0) {
            this.loading = false; // 关闭加载状态
            this.finished = true; // 加载结束
            this.total = 0
            return;
          }
          this.loading = false; // 关闭加载状态
          this.total = res.data.total; //数据总条数
          this.list = this.list.concat(rows); //合并数组
          console.log(this.list);

          // 如果合并之后的数组长度大于返回的数据总条数
          if (this.list.length >= this.total) {
            this.finished = true; // 加载结束
          }
        } else {
          this.total = 0;
          this.loading = false; // 关闭加载状态
          this.finished = true; // 加载结束
        }
      });
    },
    onSelect(action) {
      this.actionType = action.value;
      this.typeText = action.text;
      this.page = 0;
      this.list = [];

      this.onLoad();
    },
    onLoad() {
      this.page += 1;
      if (this.type == 1) {
        this.tokenList();
      } else if (this.type == 2) {
        this.usdtList();
      }
    },
  },
};
</script>
  
  <style lang="less" scoped>
.withdraw {
  height: 100%;
  background: #fff;

  .total_box {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #fff;
    background: #7938cf;
    border-radius: 12px;
    padding: 20px;
    margin: 20px;
    .total {
      font-size: 26px;
      font-weight: bold;
      margin-top: 14px;
    }
  }

  .head {
    position: relative;
    display: flex;
    justify-content: center;
    color: #000;
    background: #fff;
    padding: 20px 0 10px;
    .arrow_img {
      position: absolute;
      left: 20px;
      top: 46%;
      width: 10px;
      height: 16px;
      // transform: translate(0, -50%);
    }
  }
  .list_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
    padding: 20px 30px 14px;
    margin-top: 6px;
    // margin-bottom: 20px;
    span {
      width: 30%;
    }
  }
  .list_box {
    padding-bottom: 10px;
    .li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      color: #666666;
      padding: 10px 30px 0;
      span {
        width: 30%;
      }
    }
  }

  .bare {
    text-align: center;
    font-size: 14px;
    padding: 14px;
    color: #888;
  }
  .caption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #7938cf;
    font-size: 14px;
    font-weight: bold;
    border-left: 4px solid #7938cf;
    padding-left: 6px;
    margin: 20px 20px 10px;
    .right_arrow {
      font-size: 12px;
      font-weight: normal;
    }
  }
}
</style>