<template>
  <div>
    <div class="bg"></div>
    <Header :logoImg="indexinfo.system_logo" style="margin-top: 20px"></Header>

    <div class="home_page">
      <div class="banner">
        <van-swipe :autoplay="3000" class="my-swipe" ref="swipeRef">
          <van-swipe-item class="swipe-item" v-for="(item, index) in indexinfo.banner_list" :key="index" @click="navigatorToNotice('bulletinDetail', item.id)">
            <van-image style="width: 100%; height: 100%" radius="10" :src="item.img_url" />
          </van-swipe-item>
          <!-- <van-swipe-item class="swipe-item" style="width: 100%; height: 100%">
            <img style="width: 110%; height: 100%" radius="10" src="@/assets/images/ba.png" />
          </van-swipe-item> -->
        </van-swipe>
      </div>
      <!-- <div class="banner_box" :class="indexinfo.banner_list.length > 2 ? 'jsb' : ''">
        <div class="banner_box_item" v-for="(item, index) in indexinfo.banner_list" :key="index" @click="changeBanner(index)">
          <van-image class="banner_box_item_img" radius="10" :src="item.img_url" />
        </div>
      </div> -->
      <!-- <div class="countdown">
        <div class="text">
          <span>距离{{ countdownType.text }}还剩</span>
          <span class="text1">每天{{ countdownType.time + '准时开始' }}</span>
        </div>
        <div class="time">
          <van-count-down :time="time" @finish="finish">
            <template #default="timeData">
              <span class="block">{{ timeData.hours + '时' }}</span>
              <span class="colon">:</span>
              <span class="block">{{ timeData.minutes + '分' }}</span>
              <span class="colon">:</span>
              <span class="block">{{ timeData.seconds + '秒' }}</span>
            </template>
          </van-count-down>
        </div>
      </div> -->

      <div class="rail" @click="navigatorTo('assets')">
        <div class="f-c">
          <img src="@/assets/images/wallet/wallet.png" alt="" style="width: 16px; height: 16px; margin-right: 6px" />
          <span>查看资产</span>
        </div>
        <van-icon name="arrow" color="#fff" />
      </div>
      <div class="title">
        <span>平台介绍</span>
      </div>
      <div class="introduce">
        <div class="introduce_top" @click="navigatorToNotice('bulletinDetail', indexinfo.project_id)">
          <!-- <van-image align="left" class="introduce_top_img" radius="10" :src="indexinfo.project_img" /> -->
          <img align="left" :src="indexinfo.project_img" class="introduce_top_img" radius="10" alt="" />
          <span class="text" v-html="indexinfo.project_info"> </span>
          <!-- <p class="text">兼顾隐私性和可编程性是Aleo最大的创新之处。</p> -->
        </div>
        <div class="video" v-if="indexinfo.ad_video">
          <video controls="controls" width="100%" height="100%" :src="indexinfo.ad_video"></video>
        </div>
      </div>
      <div class="title">
        <span>公告</span>
        <span class="all" @click="navigatorTo('bulletin')">全部</span>
      </div>
      <template v-if="showLoading">
        <div style="width: 100%">
          <van-loading style="margin-left: calc(50% - 10px)" />
        </div>
      </template>
      <template v-else>
        <div class="notice" v-for="(item, index) in indexinfo.notice_list" :key="index" @click="navigatorToNotice('bulletinDetail', item.id)">
          <van-image class="notice_img" radius="10" :src="item.img_url" v-if="item.img_url" />
          <div class="right">
            <span>{{ item.title }}</span>
            <span class="time_text">{{ dateFormatFn(item.createtime) }}</span>
          </div>
        </div>
      </template>

      <div class="caption" style="padding-top: 16px">
        <span>合作伙伴</span>
      </div>
      <div class="currency_list">
        <div v-for="(item, index) of currencyList" :key="index" class="currency_li">
          <img :src="item" class="li_img" />
        </div>
      </div>

      <div class="caption">
        <span>联系我们</span>
      </div>
      <div class="relation">
        <div class="relation_li" @click="toLink(indexinfo.twitter_link)">
          <img src="@/assets/image/twitter_logo.png" class="logo_img" />
          <img src="@/assets/image/twitter.png" class="img" />
        </div>
        <div class="relation_li" @click="toLink(indexinfo.telegram_link)">
          <img src="@/assets/image/telegram_logo.png" class="logo_img" />
          <img src="@/assets/image/telegram.png" class="img" />
        </div>
      </div>
    </div>
    <!-- 绑定弹窗 -->
    <van-popup v-model="show" round style="width: 80%" :close-on-click-overlay="false">
      <div class="bind_box">
        <div style="font-weight: bold">绑定邀请地址</div>
        <div class="address_field">
          <van-field v-model="inviteCode" type="textarea" rows="1" autosize placeholder="请输入上级钱包地址" />
        </div>
        <div class="bind_button" @click="bindParent">
          <span>绑定</span>
        </div>
      </div>
    </van-popup>
    <BottomNavigation></BottomNavigation>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import { mapState } from 'vuex';
import { homeApi } from '@/api/index';
import BottomNavigation from '@/components/BottomNavigation.vue';
import { Notify } from 'vant';
import { dateFormat } from '@/utils/formatTool.js';
export default {
  data() {
    return {
      show: false, //弹窗状态
      address: '', //上级地址
      noticeText: '', //公告
      indexinfo: {
        system_name: '',
        system_logo: '',
        reservation_start: undefined, // 预约开始
        reservation_end: undefined, // 预约结束
        mate_start: undefined, // 匹配开始
        mate_end: undefined, // 匹配结束
        payment_start: undefined,
        payment_end: undefined,
        leak_start: undefined,
        leak_end: undefined,
        ad_video: '',
        project_info: undefined,
        project_img: '',
        banner_list: [],
        notice_list: [],
      },
      inviteCode: undefined,
      userinfo: {
        power: undefined,
        token: undefined,
        usdt: undefined,
        team_poser: undefined,
      },
      bindShow: false,
      time: 0,
      timeData: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      countdownType: {
        text: '开始预约',
        time: '00:00',
      },

      showLoading: true,
      currencyList: [
        require('@/assets/image/currency/currency1.png'),
        require('@/assets/image/currency/currency2.png'),
        require('@/assets/image/currency/currency3.png'),
        require('@/assets/image/currency/currency4.png'),
        require('@/assets/image/currency/currency5.png'),
        require('@/assets/image/currency/currency6.png'),
      ],
    };
  },
  created() {
    this.inviteCode = this.$route.query.inviteCode;
    if (this.inviteCode) {
      localStorage.setItem('inviteCode', this.inviteCode);
    }

    // this.getUserInfo();
  },
  mounted() {
    this.inviteCode = this.$route.query.inviteCode;
    if (this.inviteCode) {
      localStorage.setItem('inviteCode', this.inviteCode);
    }
    if (this.account) {
      this.init();
    }

    // if (!localStorage.getItem('noLogin')) {
    //   this.init();
    // }
    //注册一个事件总线  on
    this.$bus.$on('updateUserinfo', data => {
      console.log('走了事件总线', data);
      if (data == 'update') {
        this.init();
      }
    });
  },
  methods: {
    init() {
      this.getIndexInfo();
      this.getUserInfo();
    },
    // bind() {
    //   if (this.userinfo.parent_id == 0 && this.inviteCode) {
    //     // this.show = true;
    //     this.bindParent();
    //   } else if (this.userinfo.parent_id == 0 && !this.inviteCode) {
    //     this.show = true;
    //   }
    // },
    dateFormatFn(date) {
      return dateFormat(new Date(date * 1000), 'yyyy-MM-dd');
    },
    bindParent() {
      if (!this.inviteCode) {
        Notify({ type: 'danger', message: '请输入上级钱包地址' });
        this.show = true;
        return;
      }

      let data = {
        address: this.account,
        inviteCode: this.inviteCode,
        // sign: res,
      };
      //绑定上级 看信息
      homeApi.auth(data).then(res => {
        console.log('走了这里11');
        //我是绑定上级
        if (res.code == 200) {
          if (res.data.parent_id != 0) {
            Notify({ type: 'primary', message: res.msg });
            this.bindShow = false;
            this.show = false;
            this.init();
            localStorage.setItem('userinfo', JSON.stringify(res.data));
            localStorage.setItem('token__' + account, res.data.token);
            localStorage.setItem('parentId', res.data.parent_id);
          } else {
            this.show = true;
            Notify({ type: 'danger', message: res.msg });
          }
        } else {
          this.show = true;
          Notify({ type: 'danger', message: res.msg });
        }
      });
    },
    changeBanner(index) {
      this.$refs.swipeRef.swipeTo(index, { immediate: false });
    },
    getUserInfo() {
      homeApi.getUserInfo({ address: this.account, inviteCode: this.inviteCode }).then(res => {
        console.log(res);
        if (res.code == 200) {
          localStorage.setItem('userinfo', JSON.stringify(res.data));
          localStorage.setItem('parentId', res.data.parent_id);
          this.userinfo = res.data;
          if (this.userinfo.parent_id == 0) {
            this.show = true;
          }
          // if (this.userinfo.parent_id == 0) {
          //   this.bindParent();
          // }
        } else {
        }
      });
    },
    navigatorTo(name) {
      this.$router.push({ name });
    },
    navigatorToNotice(name, id) {
      this.$router.push({ name, query: { id: id } });
    },
    getIndexInfo() {
      homeApi.getIndexAllInfo().then(res => {
        if (res.code == 200) {
          this.showLoading = false;
          this.indexinfo = res.data;
        } else {
          this.showLoading = false;
        }
      });
    },
    finish() {
      this.getIndexInfo();
    },
    toLink(e) {
      window.location.href = e;
    },
    toAssets() {
      this.$router.push({ name: 'assets' });
    },
    toPage(type) {
      this.$router.push({ name: 'datainfo', query: { type } });
    },
    toTeam() {
      this.$router.push({ name: 'trade' });
    },
  },
  computed: {
    ...mapState(['account']),
  },
  watch: {
    account(e) {
      // this.init();
      // this.bind();
    },
  },
  components: {
    Header,
    BottomNavigation,
  },
};
</script>

<style lang="less" scoped>
.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 300px;
  z-index: 0;
  border-radius: 0 0 20px 20px;
  // background: linear-gradient(to bottom right, #7f84f4, #dae9fd);
}
.home_page {
  padding: 20px 16px 100px;
  .banner {
    height: 200px;
    color: #fff;
    font-size: 22px;
    border-radius: 30px;
    .my-swipe {
      width: 100%;
      height: 100%;
      border-radius: 20px;
      // .swipe-item {
      //   width: 150%;
      //   height: 100%;
      //   img {
      //     width: 150%;
      //     height: 100%;
      //   }
      // }
      // .swipe-item:first-child {
      //   position: relative;
      //   .item_text {
      //     position: absolute;
      //     z-index: 2;
      //     left: 20px;
      //     top: 40px;
      //   }
      // }
    }
  }

  .rail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding: 16px;
    border-radius: 15px;
    margin-top: 16px;
    background-color: #7938cf;

    .f-c {
      display: flex;
      align-items: center;
    }
  }

  .jsb {
    justify-content: space-between;
  }
  .banner_box {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding-top: 20px;
    .banner_box_item {
      width: 25%;
      border-radius: 10px;
      margin-right: 10px;

      &_img {
        width: 100%;
        height: 60px;
        border-radius: 10px;
        box-shadow: 4px 4px 12px 0px rgba(33, 35, 136, 0.75);
      }
    }
    .banner_box_item:last-child {
      margin-right: 0;
    }
  }
  .countdown {
    font-size: 14px;
    font-weight: 600;
    padding: 16px;
    margin-top: 20px;
    background: url('~@/assets/images/index/countdown_bg.png') no-repeat top/100% 100%;

    .text {
      display: flex;
      justify-content: space-between;
      .text1 {
        color: rgba(#ffffff, 0.8);
        font-size: 12px;
        font-weight: normal;
      }
    }
    .time {
      padding-top: 10px;
      .colon {
        display: inline-block;
        margin: 0 4px;
        color: #fff;
      }
      .block {
        display: inline-block;
        // width: 22px;
        color: #3f43df;
        font-size: 14px;
        text-align: center;
        font-weight: 600;
        padding: 2px 8px;
        border-radius: 3px;
        background-color: #ffffff;
      }
    }
  }
  .title {
    color: #7938cf;
    font-size: 14px;
    font-weight: 600;
    // font-style: italic;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .all {
      font-size: 12px;
      font-weight: 600;
      font-style: normal;
      color: rgba(#7938cf, 1);
    }
  }
  .introduce {
    color: #000;
    font-size: 12px;
    min-height: 116px;
    padding: 16px;
    border-radius: 10px;
    box-shadow: 4px 4px 15px 0px rgba(180, 212, 212, 0.29);
    background-color: #fff;
    &_top {
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      .text {
        // width: calc(100% - 130px);
        letter-spacing: 1.5px;
        line-height: 180%;

        span {
          float: left;
          color: rgba(#000, 0.85);
          // display: -webkit-box;
          // overflow: hidden;
          // white-space: normal !important;
          // text-overflow: ellipsis;
          // word-wrap: break-word;
          // -webkit-line-clamp: 7;
          // -webkit-box-orient: vertical;
        }
      }
      &_img {
        width: 120px;
        height: 86px;
        border-radius: 10px;
        margin: 0 10px 2px 0;
      }
    }
    .video {
      // height: auto;
      margin-top: 16px;
      background: #fff;
      border-radius: 10px;
      overflow: hidden;
      video {
        border-radius: 10px;
      }
    }
  }
  .notice {
    display: flex;
    // align-items: center;
    // justify-content: space-between;
    color: #000;
    padding: 16px;
    border-radius: 10px;
    // box-shadow: 0 4px 16px 0 rgba(53, 73, 93, 0.15);
    margin-bottom: 16px;
    box-shadow: 4px 4px 15px 0px rgba(180, 212, 212, 0.29);
    background-color: #fff;
    // background-image: linear-gradient(90deg, rgba(217, 239, 239, 0.3) 0, rgba(217, 239, 239, 0.3) 0, rgba(225, 240, 242, 0.3) 100%, rgba(225, 240, 242, 0.3) 100%);
    &_img {
      width: 100px;
      height: 68px;
      border-radius: 10px;
      margin-right: 10px;
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 550;

      .time_text {
        color: #7f7f7f;
        font-weight: normal;
        font-size: 13px;
        padding-top: 10px;
      }
    }
  }
  .caption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #7938cf;
    font-size: 14px;
    font-weight: 600;
    // border-left: 4px solid #238be8;
    // padding-left: 6px;
    margin-bottom: 16px;
    .right_arrow {
      display: block;
      width: 10px;
      height: 12px;
    }
  }
  .currency_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .currency_li {
      width: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      border-radius: 6px;
      height: 40px;
      padding: 0 10px;
      margin-bottom: 15px;
      .li_img {
        width: 100%;
      }
    }
  }
  .relation {
    display: flex;
    align-items: center;
    background: #fff;
    box-shadow: 0 0 4px rgba(77, 146, 223, 0.16);
    border-radius: 14px;
    padding: 20px 16px;
    .relation_li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 50%;
      .logo_img {
        display: block;
        width: 45px;
        height: 45px;
        margin-bottom: 10px;
      }
      .img {
        height: 10px;
      }
    }
  }
}
.bind_box {
  color: #000;
  padding: 20px 20px 30px;
  .address_field {
    border-bottom: 1px solid #cccccc9d;
    padding: 10px 0;
    margin: 20px 10px 20px;
  }
  .bind_button {
    color: #fff;
    text-align: center;
    background: #7938cf;
    border-radius: 20px;
    padding: 10px 0;
  }
}
</style>