<template>
  <div>
    <div class="head">
      <img @click="back" src="@/assets/images/back.png" class="arrow_img" />
      <span>报单算力互转</span>
    </div>
    <div class="withdraw">
      <div class="extract_box">
        <div class="caption">
          <span class="text_active">报单算力余额</span>
        </div>
        <!-- 钱包余额 -->
        <div class="balance">
          <span>{{ energy }}</span>
        </div>
        <div class="universal">
          <div class="slider">
            <!-- <van-slider v-model="amount" :max="max" :min="400" bar-height="8" active-color="#7938cf" button-size="16" /> -->
            <input type="number" v-model="amount" placeholder="请输入互转金额" />
            <div class="all" @click="amount = energy">
              <span>全部</span>
            </div>
          </div>
          <div>
            <span class="hint">最小{{ 0 }}算力起</span>
          </div>
          <div class="slider">
            <input type="text" v-model="address" :disabled="disable" placeholder="请输入收款地址" />
          </div>
          <div class="li">
            <span>{{ dataObj.tips }}</span>
          </div>
        </div>
      </div>
      <!-- <div class="button" v-if="ustdType == 1">
          <van-button class="submit_button" type="primary" v-if="showApprove" @click="approve">授权</van-button>
          <van-button class="submit_button" type="primary" v-else @click="submit">提交</van-button>
        </div> -->
      <div class="button">
        <van-button class="submit_button" type="primary" @click="transferEnergy">提交</van-button>
      </div>
    </div>
    <!-- 加载弹窗 -->
    <van-popup v-model="isApprove" round style="width: 80%" :close-on-click-overlay="false">
      <div class="bind_box">
        <div style="font-weight: bold"><van-loading type="spinner" /></div>
        <div class="address_field">处理中...</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { homeApi } from '@/api/index';
import { Notify } from 'vant';
import Web3 from 'web3';
export default {
  data() {
    return {
      amount: '', //提现金额
      energy: 0, // 余额
      address: '',
      isApprove: false,
      disable: false,
      dataObj: {
        token: 0,
        etc_min_amount: 0,
        tips: "提示提示提示提示提示",
      },
    };
  },
  mounted() {
    this.actionGet();
  },
  methods: {
    //返回上一页
    back() {
      this.$router.back();
    },
    actionGet() {
      homeApi.assets().then(res => {
        if (res.code == 200) {
          let obj = res.data.coin_list.filter(i => {
            return i.coin_key == 'energy';
          });
          console.log(obj);
          this.energy = obj[0].amount;
        }
      });
    },
    transferEnergy() {
      if (!this.address) {
        this.$toast('请输入收款地址');
        return;
      }
      if (!this.amount) {
        this.$toast('请输入金额');
        return;
      }
      if (this.amount > this.energy * 1) {
        this.$toast('能量不足');
        return;
      }
      this.isApprove = true;
      let params = {
        to_address: this.address,
        amount: this.amount,
      };
      homeApi
        .transferEnergy(params)
        .then(res => {
          this.isApprove = false;
          if (res.code == 200) {
            this.amount = '';
            this.address = '';
            this.$toast(res.msg);
          }
        })
        .catch(err => {
          this.isApprove = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.head {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(#000, 0.8);
  letter-spacing: 1.5px;
  font-weight: 550;
  padding: 14px 0;
  top: 0;
  width: 100%;
  background: #fff;
  .arrow_img {
    position: absolute;
    left: 20px;
    width: 10px;
    height: 16px;
  }
}
.withdraw {
  min-height: 100vh;
  padding: 66px 16px 0;

  .extract_box {
    font-size: 12px;
    color: rgba(153, 153, 153, 1);
    padding: 16px;
    box-shadow: 4px 4px 15px 0px rgba(180, 212, 212, 0.59);
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 16px;
    .caption {
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      .address {
        color: #000;
      }
      .addressInput {
        text-align: right;
        color: #000;
        border: none;
        background-color: rgba(255, 255, 255, 0.3);
      }
      .text_active {
        color: #7938cf;
      }
    }
    .balance {
      font-size: 20px;
      font-weight: bold;
      color: #000;
      margin: 12px 0 16px;
    }
    .field_box {
      font-size: 14px;
      border-bottom: 1px solid #eeeeee;
      border-top: 1px solid #eeeeee;
      padding: 20px 0;
      margin: 20px 0 16px;
      .field {
        flex: 1;
        font-size: 16px;
      }
      .input-box {
        display: flex;
        align-items: flex-end;
        color: rgba(#000, 0.8);
        padding: 16px 0 0;
        .input {
          border: none;
          background: none;
          font-size: 20px;
          font-weight: bold;

          padding-left: 2px;
        }
      }
    }
    .universal {
      display: flex;
      // align-items: center;
      flex-direction: column;
      // justify-content: space-between;
      font-size: 14px;
      color: rgba(102, 102, 102, 1);
      margin-top: 12px;
      .li {
        display: flex;
        flex-direction: column;
        line-height: 150%;
        letter-spacing: 1px;
        font-size: 13px;
        padding-top: 6px;
        // align-items: center;
        .li_amount {
          // font-weight: bold;
          color: #000;
          margin-top: 8px;
        }
      }
    }
    .slider {
      margin: 10px 0 18px;
      display: flex;
      align-items: center;
      input {
        border: 0;
        background-color: #f3f3f3;
        width: 100%;
        padding: 10px 10px;
        border-radius: 6px;
        font-size: 14px;
        color: #000;
      }
      .danwei {
        font-size: 13px;
        padding-left: 6px;
      }
    }
    .hint {
      font-size: 12px;
      color: rgba(102, 102, 102, 1);
      padding-left: 6px;
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      .text {
        color: #7938CF;
      }
    }
  }
  .button {
    display: flex;
    justify-content: center;
    .submit_button {
      color: rgba(#fff, 0.95);
      background: #7938cf;
      border: 0;
      border-radius: 12px;
      padding: 12px 0;
      width: 100%;
      margin-top: 30px;
      letter-spacing: 1.5px;
    }
  }
}
.bind_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  .address_field {
    padding-top: 16px;
  }
}
.all {
  font-size: 10px;
  white-space: nowrap;
  padding: 6px;
  border: 1px solid #7938cf;
  border-radius: 10px;
  margin-left: 10px;
}
</style>